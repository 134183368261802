<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text"> {{ title }} </v-card-title>
          <v-form ref="form">
            <v-card-text class="py-0">
              <v-container v-if="isLoading">
                <v-row>
                  <v-col>
                    <v-progress-linear
                      indeterminate
                      color="msaBlue"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="pb-0 px-0" fluid v-show="!isLoading">
                <v-row dense>
                  <v-col cols="12" v-if="attachedResourceLabel">
                    {{ attachedResourceLabel }}
                  </v-col>
                  <v-col cols="12" v-if="taskTemplate.resourceName">
                    <strong>{{ taskTemplate.resourceName }}</strong>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="mt-2 mb-2">
                      <v-btn
                        v-if="showRemoveButton"
                        color="red"
                        dark
                        class="ml-2"
                        @click="removeResource"
                        >Remove</v-btn
                      >
                      <DocumentSelectorDialog
                        class="ml-2"
                        :buttonLabel="documentButtonLabel"
                        @select="onDocumentSelect"
                      ></DocumentSelectorDialog>
                      <CourseSelectorDialog
                        class="ml-2"
                        :buttonLabel="courseButtonLabel"
                        @select="onCourseSelect"
                      >
                      </CourseSelectorDialog>
                    </v-row>
                  </v-col>
                  <v-col cols="12"> Task Name </v-col>
                  <v-col cols="12">
                    <v-text-field
                      class="py-0"
                      dense
                      required
                      counter
                      maxlength="1000"
                      outlined
                      v-model="taskTemplate.name"
                      :rules="[rules.name, rules.required]"
                      data-testid="task-template-name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12"> Deadline (in days) </v-col>
                  <v-col cols="12">
                    <v-text-field
                      class="py-0"
                      dense
                      outlined
                      v-model="taskTemplate.dueDays"
                      type="number"
                      min="1"
                      max="5000"
                      :rules="[rules.dueDaysRules]"
                      data-testid="deadline-name"
                      @keydown.187.prevent
                      @keydown.189.prevent
                      @keydown.69.prevent
                      @keydown.190.prevent
                    >
                      <!-- the above prevents '+', '-', '.' and 'e' -->
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions class="pa-0" v-show="!isLoading">
            <v-container class="px-4" fluid>
              <v-row class="pb-4 px-3">
                <TaskTemplateDeleteDialog
                  v-if="!isNew"
                  :taskTemplate="taskTemplate"
                  label="Delete"
                  @deleted="gotoList"
                />
                <v-spacer></v-spacer>
                <v-btn class="mr-4 red--text" text @click="cancel"
                  >Cancel</v-btn
                >
                <v-btn
                  class="msaBlue white--text"
                  :disabled="disableSave"
                  @click="validate()"
                >
                  Save
                </v-btn>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DocumentSelectorDialog from '@/components/TrainingTasks/DocumentSelectorDialog.vue';
import CourseSelectorDialog from '@/components/TrainingTasks/CourseSelectorDialog.vue';
import TaskTemplateDeleteDialog from '@/components/TrainingTasks/TaskTemplateDeleteDialog.vue';

export default {
  name: 'TaskTemplateDetailPage',
  components: {
    DocumentSelectorDialog,
    CourseSelectorDialog,
    TaskTemplateDeleteDialog,
  },
  data() {
    return {
      isLoading: false,
      taskTemplate: {
        position: 1,
        taskGroupTemplateId: null,
        dueDays: null,
        resourceUnavailable: false,
        updatedAt: null,
        resourceName: null,
        taskTypeId: this.$constants.TASK_TYPES.REGULAR,
        id: null,
        updater: null,
        resourceId: null,
        name: null,
      },
      original: {},
      rules: {
        name: (v) => {
          return !v || !!v.trim().length || 'This field is required.';
        },
        required: (v) => !!v || 'This field is required.',
        positiveInteger: (v) =>
          parseInt(v) > 0 || 'Field needs to be a positive number.',
        dueDaysRules: (v) =>
          !v ||
          (!isNaN(v) &&
            parseInt(v) == v &&
            parseInt(v) > 0 &&
            parseInt(v) <= 5000) ||
          'Due days must be 1-5000.',
      },
    };
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.taskTemplate) === JSON.stringify(this.original) ||
        !this.taskTemplate.name ||
        this.taskTemplate.name.trim().length == 0
      );
    },
    title() {
      if (this.isNew) {
        return 'Add Task';
      }
      return 'Task Details';
    },
    isNew() {
      return this.$route.name == 'TaskTemplateNew';
    },
    attachedResourceLabel() {
      let label = null;
      switch (this.taskTemplate.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          label = 'Attached Document';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          label = 'Attached Training';
          break;
        default:
          label = null;
      }
      return label;
    },
    showRemoveButton() {
      return this.taskTemplate.taskTypeId != this.$constants.TASK_TYPES.REGULAR;
    },
    documentButtonLabel() {
      let label = 'Attach Document';
      if (this.taskTemplate.taskTypeId == this.$constants.TASK_TYPES.DOCUMENT) {
        label = 'Replace Document';
      }
      return label;
    },
    courseButtonLabel() {
      let label = 'Attach Course';
      if (this.taskTemplate.taskTypeId == this.$constants.TASK_TYPES.TRAINING) {
        label = 'Replace Course';
      }
      return label;
    },
  },
  methods: {
    onDocumentSelect(doc) {
      this.taskTemplate.taskTypeId = this.$constants.TASK_TYPES.DOCUMENT;
      this.taskTemplate.resourceId = doc.id;
      this.taskTemplate.resourceName = doc.name;
      if (!this.taskTemplate.name || !this.taskTemplate.name.length) {
        this.taskTemplate.name = `Review ${doc.name}`;
      }
    },
    gotoList() {
      this.$router.go(-1);
    },
    cancel() {
      this.gotoList();
    },
    onCourseSelect(course) {
      this.taskTemplate.taskTypeId = this.$constants.TASK_TYPES.TRAINING;
      this.taskTemplate.resourceId = course.id;
      this.taskTemplate.resourceName = course.name;
      if (!this.taskTemplate.name || !this.taskTemplate.name.length) {
        this.taskTemplate.name = `Complete ${course.name}`;
      }
    },
    removeResource() {
      this.taskTemplate.resourceName = '';
      this.taskTemplate.resourceId = 0;
      this.taskTemplate.taskTypeId = this.$constants.TASK_TYPES.REGULAR;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    save() {
      let url = this.isNew
        ? 'add-task-template?format=json'
        : 'update-task-template?format=json';
      this.taskTemplate.loaderText = 'Saving...';
      this.$axios.post(url, this.taskTemplate).then(() => {
        this.$router.push({
          name: 'TaskTemplates',
        });
      });
    },
    setData(data) {
      this.taskTemplate = data;
      this.original = JSON.parse(JSON.stringify(this.taskTemplate));
      this.$store.commit('setSelectedTaskTemplateName', this.taskTemplate.name);
      this.$store.commit('updateCrumbRefresh');
    },
    getTaskTemplate() {
      this.isLoading = true;
      const params = {
        taskTemplateId: this.$route.params.id,
      };
      this.$axios
        .post('get-task-template?format=json', params)
        .then((response) => {
          this.isLoading = false;
          this.setData(response.data);
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$router.push({ name: 'TaskTemplates' });
          }
        });
    },
    loadUrlParams() {
      if (this.isNew) {
        this.taskTemplate.taskGroupTemplateId = this.$route.params.id;
      } else {
        if (this.$route.params.id) {
          if (isNaN(parseInt(this.$route.params.id))) {
            this.$router.push({ name: 'TaskTemplates' });
            return;
          }
          this.getTaskTemplate();
        }
      }
    },
  },
  beforeMount() {
    //pinging the server just to fore a session
    this.$axios.post('public/ping?format=json', {});
    this.loadUrlParams();
  },
};
</script>
